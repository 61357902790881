import {useNavigate, useParams} from 'react-router-dom';
import {type FC} from 'react';
import {captureMessage, setUser, withScope} from '@sentry/react';

import {logout, requestLogin} from 'authentication/actions/action';
import {changeLocale} from 'i18n/actions/actions';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type RequestLoginSuccess} from 'authentication/actions/interface';

import DashboardLogin from '../components/DashboardLogin';

interface LoginUrlParams extends Record<string, string | undefined> {
  token: string;
  uri?: string;
}

const DashboardLoginContainer: FC = () => {
  const {token, uri = ''} = useParams<LoginUrlParams>();
  const dispatch = useAxiosDispatch();
  const navigate = useNavigate();

  const login = async () => {
    try {
      const loginResponse = await dispatch<RequestLoginSuccess>(requestLogin(token));
      const locale = loginResponse.payload.data.user.locale;
      const role = loginResponse.payload.data.user.role;
      setUser({
        id: String(loginResponse.payload.data.user.id)
      });
      await dispatch(changeLocale(locale));

      const previousLocation: string = decodeURIComponent(uri);
      if (previousLocation.length !== 0) {
        navigate(previousLocation, {replace: true});
      } else if (role === 'methodist') {
        navigate('/library', {replace: true});
      } else {
        let classRoomUrl = '/room';
        if (loginResponse.payload.data.studentTeacherId) {
          classRoomUrl += `/${loginResponse.payload.data.studentTeacherId}`;
          if (loginResponse.payload.data.courseInstanceId) {
            classRoomUrl += `/course/${loginResponse.payload.data.courseInstanceId}`;
          }
        }
        navigate(classRoomUrl, {replace: true});
      }
      return true;
    } catch (e) {
      withScope(scope => {
        scope.setExtras({error: e.error.response || e});
        captureMessage('User login failed', 'warning');
      });
      dispatch(logout('/'));
      return false;
    }
  };

  return <DashboardLogin login={login} />;
};

export default DashboardLoginContainer;

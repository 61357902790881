import React from 'react';

interface LoginStateProps {}

interface LoginDispatchProps {
  login: () => Promise<boolean>;
}

interface Props extends LoginStateProps, LoginDispatchProps {}

class DashboardLogin extends React.Component<Props, {}> {
  public componentDidMount(): void {
    this.props.login();
  }

  public render() {
    return null;
  }
}

export default DashboardLogin;
